.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 960px;
    margin: 60px auto;
}
.content {
    padding-right: 30px;
}
.sidebar {
    padding-left: 30px;
}
.sidebar form {
    padding: 20px;
    background: #4f46e5;
    border-radius: 10px;
}
.sidebar input,
.sidebar select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
}
.sidebar label {
    margin: 0 auto 20px;
    display: block;
    color: #fff;
}
.sidebar button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
}
.sidebar h3 {
    color: #4f46e5;
    margin-bottom: 20px;
}
.sidebar aside {
    margin-top: 40px;
    color: #555;
}
.sidebar aside li {
    margin: 10px;
}

/* transactions */
.transactions li {
    margin: 40px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border-left: 4px solid #4f46e5;
    transition: box-shadow 0.1s;
}
.transactions li:hover {
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.02), 0px 8px 6px rgba(0, 0, 0, 0.03),
        0px 16px 10px rgba(0, 0, 0, 0.024), 0px 26px 18px rgba(0, 0, 0, 0.05);
}
.transactions .name {
    color: #777;
    font-size: 1.4em;
}
.transactions .amount {
    margin-left: auto;
    margin-right: 40px;
    color: #777;
    font-weight: bold;
    font-size: 1.6em;
}
.transactions button {
    position: absolute;
    top: 0;
    right: 0;
    background: #ddd;
    color: #777;
    border: none;
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
}
