@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* base styles */
body {
    font-family: Poppins, sans-serif;
    margin: 0;
    font-size: 1.1em;
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}
ul {
    list-style-type: none;
}
.btn {
    background: none;
    border: 2px solid #4f46e5;
    padding: 6px 12px;
    border-radius: 4px;
    color: #4f46e5;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
}
.btn:hover {
    background: #4f46e5;
    color: #fff;
}

.error-msg {
    color: #e11d48;
}
